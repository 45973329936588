<template>
  <BModal
    id="modal-review-reject"
    ref="modal-review-reject"
    no-stacking
    centered
    hide-footer
    no-close-on-backdrop
    no-close-on-esc
    hide-header-close
    size="lg"
    hide-header
  >
    <div class="mb-1 text-bold text-black text-semibold text-xl">
      {{ dataUserChild.title }}
    </div>
    <BFormGroup
      label=""
      label-for="catatan-penarikan-textarea"
      invalid-feedback="Silahkan isi catatan"
    >
      <BFormTextarea
        v-model="notes"
        placeholder="Berikan catatan kamu untuk partner dalam penarikan"
        rows="8"
        required
        :formatter="validMaxLength"
      />
      <BRow class="justify-content-between">
        <small
          class="text-danger ml-1"
        >
          {{ errorMessage }}
        </small>
        <small class="mr-1 mt-50">
          {{ notes.length }}/250
        </small>
      </BRow>
    </BFormGroup>
    <div class="w-100 flex justify-end">
      <BButton
        variant="outline-primary"
        class="mr-1 text-center"
        @click="closeModal"
      >
        Batal
      </BButton>
      <BButton
        variant="primary"
        class="text-center"
        :disabled="checkValidityDisabled()"
        @click="onFinish"
      >
        <BSpinner
          v-if="isLoading"
          variant="light"
          small
        />
        {{ dataUserChild.button }}
      </BButton>
    </div>

  </BModal>
</template>

<script>
import { collaboratorAxiosIns } from '@/libs/axios'

export default {
  props: {
    getDetailWithdrawal: {
      type: Function,
      default: () => {},
    },
    dataUserChild: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      notes: '',
      isLoading: false,
      disableBtn: false,
      errorMessage: '',
    }
  },
  watch: {
    notes() {
      if (this.notes === '') {
        this.errorMessage = 'Catatan harus di isi'
      } else {
        this.errorMessage = ''
      }
    },
  },
  methods: {
    async onFinish() {
      this.isLoading = true
      this.disableBtn = true
      const url = `/v1/admin/withdrawals/${this.dataUserChild.id}/confirm`
      const status = this.dataUserChild.button === 'Review & Submit' ? 'review' : 'rejected'
      const formData = new FormData()
      formData.append('user_id', this.dataUserChild.user_id)
      formData.append('status', status)
      formData.append('note', this.notes)

      await collaboratorAxiosIns.put(url, formData)
        .then(() => {
          this.$toast_success({ message: 'Withdrawal Berhasil', timeout: 1000 })
          this.$bvModal.hide('modal-review-reject')
          this.getDetailWithdrawal()
          this.isLoading = false
          this.disableBtn = true
          this.errorMessage = ''
        })
        .catch(err => {
          this.$toast_error({ message: 'Withdrawal Gagal', timeout: 1000 })
          this.isLoading = false
          this.disableBtn = true
        })
    },
    closeModal() {
      this.errorMessage = ''
      this.notes = ''
      this.$nextTick(() => {
        this.$bvModal.hide('modal-review-reject')
      })
    },
    validMaxLength(e) {
      return String(e).substring(0, 250)
    },
    checkValidityDisabled() {
      if (this.notes.length < 3) {
        return true
      }
      return false
    },
  },
}
</script>
